<template>
  <div>
    <div
      :key="key"
      v-for="(link, key) in listSubClassifiers.filter(
        (data) =>
          !searchSubClassifiers ||
          data.name.toLowerCase().includes(searchSubClassifiers.toLowerCase())
      )"
    >
      <el-link @click="reLocate(link.id)" type="success">
        {{ link.title }}
      </el-link>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "subclassifiersTable",
  data() {
    return {
      searchSubClassifiers: "",
    };
  },

  computed: {
    ...mapGetters({
      listSubClassifiers: "GETLISTSUBCLASSIFIERS",
    }),
  },

  methods: {
    reLocate(link) {
      this.$router.push(link);
    },

    openSubClassifier(raw) {
      console.log(raw.id);
      this.$router.push(raw.id);
    },

    getListAllSubClassifiers() {
      this.$store.dispatch("getListAllSubClassifiers");
    },
  },

  mounted() {
    this.getListAllSubClassifiers();
  },
};
</script>

<style scoped></style>
