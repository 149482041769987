<template>
  <div>
    <subclassifiers-main></subclassifiers-main>
  </div>
</template>

<script>
import SubclassifiersMain from "@/components/admin/subclassifiers/subclassifiersMain";
export default {
  name: "subclassifiersMainView",
  components: { SubclassifiersMain },
};
</script>

<style scoped></style>
