<template>
  <div>
    <h2>Подсправочники</h2>

    <subclassifiers-table></subclassifiers-table>
  </div>
</template>

<script>
import SubclassifiersTable from "@/components/admin/subclassifiers/subclassifiersTable";
export default {
  name: "subclassifiersMain",
  components: { SubclassifiersTable },
};
</script>

<style scoped></style>
